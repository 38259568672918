jQuery(document).ready( function($){
    $('div:not(.replacewithus) > #name, div:not(.replacewithus) > #headline, div:not(.replacewithus) > #title').keyup(function(){
        var text = convertToSlug($(this).val());
        $("#slug").val(text);
    });
    $('.replacewithus > #name, .replacewithus > #headline, .replacewithus > #title').keyup(function(){
        var text = convertToElement($(this).val());
        $("#slug").val(text);
    });
    $('select.search-box').change(function(){
        $(this).closest('form').submit();
    });
    $("input#select-all" ).on( "click", function() {
        if($(this).is(":checked")) {
            $('.id_checkbox').prop('checked', true);
        } else {
            $('.id_checkbox').prop('checked', false);
        }
    });
    $('a.advance-search-link').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $collapse = $this.closest('.collapse-group').find('.collapse');
        $('.advance-search-div').collapse('toggle');
    });
    $('.datepicker').datepicker({
        'dateFormat': 'yy-mm-dd'
    });
});
function convertToSlug(Text)
{
    Text = Text.trim();
    return Text
        .toLowerCase()
        .replace(/\./g, ' ')
        .replace(/&/g, 'and')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}
function convertToElement(Text)
{
    Text = Text.trim();
    return Text
        .toLowerCase()
        .replace(/\./g, ' ')
        .replace(/&/g, 'and')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'_')
        ;
}